<template>
  <main id="main">
    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="breadcrumb-hero">
        <div class="container">
          <div class="breadcrumb-hero">
            <h2>About</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <ol>
          <li><router-link :to="{ name: 'home' }">Home</router-link></li>
          <li>About</li>
        </ol>
      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="row">

          <div class="col-lg-6 video-box align-self-baseline position-relative">
            <div class="d-flex w-100 flex-column" style="height: 500px;">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28287.5450198104!2d152.62811611710404!3d-27.595292783659993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b96b9cd006437a3%3A0xcde08d446456285f!2s237+Mt+Marrow+Quarry+Rd!5e0!3m2!1sen!2sau!4v1401255333502"
                class="map-frame"></iframe>
            </div>
          </div>

          <div class="col-lg-6 pt-3 pt-lg-0 content">
            <h3>Accessing the Quarry.</h3>
            <p>
              The Quarry is located on Mt Marrow Quarry Rd at Mt Marrow. Easiest access is from the Warrego Highway (Darren Lockyer Way) at Haigslea via the
              Haigslea-Malabar Rd, between the Walloon and Marburg turnoffs.<br/>
              <br/>
              Access is also available from the Cunningham Highway at Willowbank via Walloon on the Haigslea-Amberley Rd, Karrabin-Rosewood Rd and Caledonian Rd.<br />
              <br/>
              Karribin-Rosewood Rd also provides access to Ipswich's Western suburbs via Walloon and to Rosewood and surrounding areas.
            </p>
            <h4>Load Limits</h4>
            <p>
              Please note that there are several roads closed to heavy traffic (Load or Tare exceeding 10 tonnes in most cases) around the Mt Marrow area that need to be respected.<br/>
              <br/>
              <b>Thagoona-Haigslea Rd between Mt Marrow Quarry Rd and Karrabin-Rosewood Rd</b><br/>
              - Use Caledonian Rd<br/>
              <br/>
              <b>Taylors Rd between Thagoona-Haigslea Rd and Haigslea-Amberley Rd</b><br/>
              - Use Caledonian Rd<br/>
              <br/>
              <b>Haigslea-Malabar Rd between Mt Marrow Quarry Rd and Rosewood-Marburg Rd</b><br/>
              - Use the Warrego Highway<br/>
            </p>

            <p>
              Please observe the restriction signs posted on these and other load limited sections of road.
            </p>
          </div>

        </div>

      </div>
    </section><!-- End About Section -->
  </main>
</template>
<style scoped>
.map-frame {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}
</style>
